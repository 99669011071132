.button {
  min-width: 158px;
  height: 68px;
  min-height: 68px;
  border: none;
  border-radius: 100px;
  outline: none;
  position: relative;

  &:hover {
    cursor: pointer;
    &:after {
      opacity: 1;
      right: 18px;
    }
  }
  &.white {
    background-color: white;
    border: 1px solid #51bdef;
    color: #51bdef;
  }

  &.blue  {
    background-color: #51bdef;
    border: 1px solid #51bdef;
    color: white;
  }
}
.button  {
  color: white;
  font-weight: 500;
  font-size: 14px;
  &:after {
    font-size: 16px;
    opacity: 0;
    content: '>';
    opacity: 0;
    position: absolute;
    margin-left: -1em;
    transition: all 0.2s;
    text-transform: none;
    font-variant: none;
    font-style: normal;
    font-weight: 400;
    text-shadow: none;
    right: 20px;
  }

  &:hover {
    text-decoration: none;
  }
}
