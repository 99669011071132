@import '../../styles/customMediaQueries.css';

.root {
  padding: 0 24px;
  @media (--viewportMedium) {
    padding: 0;
  }
}

.selectError {
  border: var(--borderErrorField);
}
