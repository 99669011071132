@import '../../styles/customMediaQueries.css';

.root {
  margin: 0;
  padding-left: 14px;
}

.twoColumns {
  @media (--viewportMedium) {
    column-count: 2;
  }
}

.item {
  display: flex;
  align-items: center;
  padding: 3px 0;

  @media (--viewportMedium) {
    padding: 5px 0 3px 0;
  }
}

.checkIcon {
}

.hidden {
  visibility: hidden;
}

.marketplaceFill {
  fill: var(--colorBlack);
}

.iconWrapper {
  align-self: baseline;
  margin-right: 8px;

  /* This follows line-height */
  height: 24px;
}

.labelWrapper {
  display: inline-block;
}

.selectedLabel,
.notSelectedLabel {
  composes: marketplaceBodyFontStyles from global;
  line-height: 24px;
  margin: 0;
}

.selectedLabel {
  font-weight: var(--fontWeightSemiBold);
  font-size: 15px;
}

.notSelectedLabel {
  color: var(--colorGrey300);
  position: relative;
  text-decoration: line-through;

}
.otherText {
  font-size: 14px;
  font-style: italic;
}