@import '../../styles/customMediaQueries.css';

.contactUsSection {
  & .top {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMjBweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiMyZjhjZmYiPjxwYXRoIGQ9Ik0xMjgwIDMuNEMxMDUwLjU5IDE4IDEwMTkuNCA4NC44OSA3MzQuNDIgODQuODljLTMyMCAwLTMyMC04NC4zLTY0MC04NC4zQzU5LjQuNTkgMjguMiAxLjYgMCAzLjRWMTQwaDEyODB6IiBmaWxsLW9wYWNpdHk9Ii4zIi8+PHBhdGggZD0iTTAgMjQuMzFjNDMuNDYtNS42OSA5NC41Ni05LjI1IDE1OC40Mi05LjI1IDMyMCAwIDMyMCA4OS4yNCA2NDAgODkuMjQgMjU2LjEzIDAgMzA3LjI4LTU3LjE2IDQ4MS41OC04MFYxNDBIMHoiIGZpbGwtb3BhY2l0eT0iLjUiLz48cGF0aCBkPSJNMTI4MCA1MS43NmMtMjAxIDEyLjQ5LTI0Mi40MyA1My40LTUxMy41OCA1My40LTMyMCAwLTMyMC01Ny02NDAtNTctNDguODUuMDEtOTAuMjEgMS4zNS0xMjYuNDIgMy42VjE0MGgxMjgweiIvPjwvZz48L3N2Zz4=);
    background-size: 200% 120px;
    bottom: 0;
    height: 120px;
    z-index: 10;
    transform: scale(1, 1);
  }
  & > div:nth-child(2) {
    height: 700px;
    padding-top: 15px;
    padding-bottom: 1px;
    background-color: var(--colorDarkBlue);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 36px;
  }

  & .bottom {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMjBweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiNmZmZmZmYiPjxwYXRoIGQ9Ik0xMjgwIDMuNEMxMDUwLjU5IDE4IDEwMTkuNCA4NC44OSA3MzQuNDIgODQuODljLTMyMCAwLTMyMC04NC4zLTY0MC04NC4zQzU5LjQuNTkgMjguMiAxLjYgMCAzLjRWMTQwaDEyODB6IiBmaWxsLW9wYWNpdHk9Ii4zIi8+PHBhdGggZD0iTTAgMjQuMzFjNDMuNDYtNS42OSA5NC41Ni05LjI1IDE1OC40Mi05LjI1IDMyMCAwIDMyMCA4OS4yNCA2NDAgODkuMjQgMjU2LjEzIDAgMzA3LjI4LTU3LjE2IDQ4MS41OC04MFYxNDBIMHoiIGZpbGwtb3BhY2l0eT0iLjUiLz48cGF0aCBkPSJNMTI4MCA1MS43NmMtMjAxIDEyLjQ5LTI0Mi40MyA1My40LTUxMy41OCA1My40LTMyMCAwLTMyMC01Ny02NDAtNTctNDguODUuMDEtOTAuMjEgMS4zNS0xMjYuNDIgMy42VjE0MGgxMjgweiIvPjwvZz48L3N2Zz4=);
    background-size: 200% 120px;
    bottom: 0;
    height: 66px;
    z-index: 10;
    transform: scale(1, 1);
    margin-top: -70px;
    @media (--viewportMedium) {
      height: 86px;
      margin-top: -120px;
    }
  }

  & .title {
    font-weight: 300;
    font-size: 50px;
    line-height: 70px;
    text-align: center;
    color: var(--colorWhite);
  }
}

.informationContainer {
  max-width: 1080px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 18px;
  @media (--viewportMedium) {
    flex-direction: row;
    align-items: flex-start;
    gap: 0;
  }
}
.informationContainer .informationLeftContainer a,
.informationRightContainer a {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
  color: var(--colorWhite);

  &:hover {
    text-decoration: none;
  }
}
.informationContainer .informationLeftContainer a p,
.informationRightContainer a p {
  margin: 0;
  padding: 0;
}
.informationContainer .informationLeftContainer a:first-of-type {
  margin-bottom: 8px;
  margin-top: 9px;
  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
}
.informationContainer .informationRightContainer a:first-of-type {
  margin-bottom: 8px;
  margin-top: 4px;
  @media (--viewportMedium) {
    margin-bottom: 30px;
    margin-top: 4px;
  }
}
.addressBox {
  display: flex;
  flex-direction: column !important;
}
.addressBox p:first-of-type {
  margin-bottom: 12px !important;
  font-size: 20px;
  font-weight: 500;
  line-height: 34px;
  color: var(--colorWhite);
}
.addressBox p:last-of-type {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: var(--colorWhite);
}
.socialContainer {
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
}
